.page-404 {
    &__hl {
        /*padding: calc(var(--gs) * 9);*/
        padding: calc(var(--gs) * 2);
        color: var(--cl-grey);
        margin: 0;
        font-weight: 300;
        font-size: 150px;
        line-height: 100%;

        @media (--tablet) {
            /*padding: calc(var(--gs) * 4);
            margin-bottom: calc(var(--gs) * 3);
            margin-bottom: 0;*/
            font-size: 80px;
        }
    }

    &__main {
        @media (--tablet-lg-min) {
            display: flex;
            justify-content: center;
        }
    }

    &__section {
        margin: calc(var(--gs) * 2) 0;
        padding: calc(var(--gs) * 3) 0;

        @media (--tablet-lg-min) {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        @media (--tablet) {
            margin: 0;
            min-height: calc(var(--gs) * 50);
            display: flex;
            align-items: center;
        }
    }

    &__container {
        @media (--tablet) {
            padding: 0;
        }
    }

    &__img {
        margin-right: calc(var(--gs)*12);
        margin-bottom: calc(var(--gs)*1);
        @media (--tablet-lg) {
            margin-right: calc(var(--gs)*2);
        }
        @media (--tablet) {
            margin: 0;
        }
    }
    &__btn1 {
        margin-right: 40px;
        @media (--tablet-lg) {
            margin-right: 0;
        }
    }
    &__content {
        display: flex;
        /*align-items: center;
        justify-content: center;
        width: calc(var(--gs) * 100);
        height: calc(var(--gs) * 53);
        background-image: url('../../../images/inhtml/courses-background-big.png');*/
        background-size: contain;
        background-repeat: no-repeat;

        @media (--tablet-lg) {
            width: 100%;
            height: auto;
        }

        @media (--tablet) {
            /*background-image: url('../../../images/inhtml/courses-background-small.png');
            background-size: cover;
            background-position-y: -90px;*/
            flex-direction: column;
            width: 100%;
            height: auto;
        }
    }

    &__desc {
        font-weight: 500;
        font-size: 21px;
        line-height: 32px;
        margin-bottom: calc(var(--gs) * 4);

        @media (--tablet-lg) {
            margin-bottom: calc(var(--gs) * 3);
            font-size: 18px;
            line-height: 24px;
        }

        @media (--tablet) {
            margin-bottom: calc(var(--gs) * 2.5);
            font-size: 14px;
            line-height: 20px;
        }
    }

    &__error {
        background: white;
        text-align: center;
    }
}
